<template>
  <div class="relative md:min-h-[calc(100vh-250px)] overflow-x-hidden">
    <EpiserverContentArea
      v-if="model.activatePageHero && model.content"
      :model="[model.content[0]]"
      class="blockAreaHero full-width-content-area"
    />
    <div class="layout-container mx-auto">
      <BreadcrumbsSmall
        v-if="!model.hideBreadCrumbs && parent"
        :class="{
          'breadcrumbs--white' : model.activatePageHero
        }"
        :parent="parent"
      />
      <div
        class="mx-auto md:flex"
        :class="{
          'lg:w-10/12' : model.contentRight,
        }"
      >
        <div
          v-if="model.content"
          v-epi-edit="'Content'"
          class="blockAreaLeft"
          :class="{
            'w-full md:pr-64' : model.contentRight,
            'mx-auto md:w-3/4 lg:w-1/2' : !model.contentRight,
          }"
        >
          <EpiserverContentArea
            :model="model.activatePageHero ? computedBlocks : model.content"
            placement="left"
          />
        </div>
        <div
          v-if="model.contentRight"
          class="blockAreaRight content-side md:w-1/3 flex-shrink-0 md:pl-20"
        >
          <EpiserverContentArea
            v-epi-edit="'ContentRight'"
            :model="model.contentRight"
            placement="right"
          />
        </div>
      </div>
    </div>
    <EpiserverContentArea
      v-if="model.contentBottom"
      v-epi-edit="'ContentBottom'"
      :model="model.contentBottom"
      :class="{
        'content-bottom': model.content,
        'pt-48': (model.hideBreadCrumbs || !parent) && !model.content
      }"
      class="blockAreaBottom full-width-content-area"
      placement="bottom"
      :full-width="true"
    />
  </div>
</template>

<script setup lang="ts">
import type { StandardBlockPage } from '~/content-types';
import { computed, ref } from 'vue';
import { ContentLoader } from '@episerver/content-delivery';
import BreadcrumbsSmall from '~/components/body/BreadcrumbsSmall.vue';

import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
const { isEpiEdit, isEpiPreview } = storeToRefs(usePageContentStore());

const contentLoader = new ContentLoader();
let parent = ref();

const props = defineProps<{
  model: StandardBlockPage;
}>();

// Remove first block if active Page hero
const computedBlocks = computed(() => {
  // New array because of read only props
  const blocksArray = [];
  const content = props.model.content;
  if (content) {
    for (let i = 0; i < content.length; i++) {
      if (i !== 0) {
        blocksArray.push(content[i]);
      }
    }
  }
  return blocksArray;
});

/**
 * Check for ancestors
 * Fails quitly
 */
const { data } = await useAsyncData(() => {
  return new Promise((resolve, reject) => {
    contentLoader.getAncestors(props.model.contentLink.guidValue, 
      { select: ['name', 'url'] })
      .then((ancestors) => {
        resolve(ancestors);
      })
      .catch((err) => {
        // getAncestors doesn't work on preview of unpublished pages
        if (!isEpiPreview && !isEpiEdit) {
          console.warn('Couldn\'t load ancestors');
          console.error(err);
        }
        resolve([]);
      })
    ;
  });
});
const ancestors = data.value as object[];

if (ancestors[0]) {
  parent.value = ancestors[0];
}
</script>
<style lang="postcss">
.blockAreaLeft {
  section > div:first-of-type {
    .layout-container {
      @apply pt-0;
    }
  }
}

.blockAreaRight {
  section > div:first-of-type {
    .layout-container {
      @apply pt-0;
    }
  }
}
</style>
